.blog-body{
    height: 92vh;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    position: absolute;
    overflow: hidden;
    top: 8vh;
}
.newsletter-container{
    height: 100%;
    width: 100%;
    padding: 2em;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
}
.newsletter-body{
    width: fit-content;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.newsletter-header{
    height: 25%;
    width: 100%;
}
.blog-title{
    font-size: 2.5em;
    margin: 0;
}
.newsletter-list{
    height: 65%;
    width: 40vw;
    padding: 1em;
    box-shadow: 3px 3px #14283A;
    background-color: #326491;
    box-sizing: border-box;
    overflow: scroll;
}
.newsletter-option{
    width: 100%;
    height: fit-content;
    background-color: #E5E4E2;
    display: flex;
    padding: 1em;
    box-sizing: border-box;
    box-shadow: 3px 3px #14283A;
    border: 1px solid #14283A;
    justify-content: space-between;
    margin-bottom: 1em;
    color: #326491;
    cursor: pointer;
}
.newsletter-option:hover{
    animation: colorFade 0.5s ;
    background-color: white;
}
.newsletter-content{
    box-shadow: 3px 3px #14283A;
    background-color: white;
    border: 1px solid #14283A;
    width: 45vw;
    height: 100%;
    box-sizing: border-box;
    padding: 1em;
}
@media only screen and (max-width: 500px) {
    .blog-body{
        height: fit-content;
    }
    .newsletter-container{
        flex-direction: column;
        padding: 2em 1em;
        height: fit-content;
    }
    .newsletter-body{
        width: 100%;
        margin-bottom: 1em;
        height: 50vh;
    }
    .newsletter-list{
        width: 100%;
    }
    .newsletter-content{
        width: 100%;
        height: 80vh;
    }
}