.carousel {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}  
.carousel-slide {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-sizing: border-box;
    flex-direction: column;
}
.carousel-slide > p{
    color: black;
    font-size: 1em;
}
.carousel-button {
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding: 0;
}
/* .carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    height: 10%;
} */
.movement-buttons{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    justify-content: space-evenly;
    height: 10%;
}
@media only screen and (max-width: 800px) {
    .carousel{
        padding: 0;
    }
}