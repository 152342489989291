.minutes-page-container{
    height: 92vh;
    width: 100vw;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    top: 8vh;
}
.minutes-list{
    width: fit-content;
    box-sizing: border-box;
    padding: 2em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.minutes-header{
    font-size: 3em;
}
.minutes-description{
    box-sizing: border-box;
    width: fit-content;
    background-color: white;
    max-width: calc(100vw - 4em);
    padding: 1em;
    border: 1px solid #14283a;
    box-shadow: 2px 2px #14283a;
    height: fit-content;
}
.minute-links{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: #326491;
    box-shadow: 3px 3px #14283a;
    padding: 1em;
    height: 60%;
    border: 1px solid #14283a;
    overflow: scroll;
}
.minute-links > a{
    font-size: 1em;
    width: fit-content;
    text-decoration: none;
    background-color: #E5E4E2;
    color: #326491;
    margin-bottom: 1.5em;
    padding: 1em 0.5em;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #14283a;
    box-shadow: 3px 3px #14283a;
}
.minute-links > a:hover{
    background-color: white;
    border: 1px solid #14283a;
    color: #326491;
    animation: colorFade 0.5s ;
    transform: translate(-1%, -1%);
    box-shadow: 4px 4px #14283a;
    cursor: pointer;
}
.minutes-image{
    width: 50vw;
    height: 100%;
    position: relative;
}
.minutes-image > img{
    width: 85vw;
    height: auto;
    bottom: 0%;
    position: absolute;
    transform: translate(-35%, 5%);
    z-index: -2;
}
@keyframes colorFade {
    0% { background-color: #E5E4E2; }
    100% { background-color: white; opacity: 100%; }
}
@media only screen and (orientation: landscape){
    .minutes-description{
        max-width: none;
        min-width: calc(50vw - 4em);
    }
}
@media only screen and (orientation: portrait){
    .minutes-image{
        width: 100%;
    }
    .minutes-header{
        margin-bottom: 2vw;
    }
    .minutes-description{
        width: 100%;
        margin-bottom: 4vw;
    }
    .minutes-image > img{
        width: 110vw;
        transform: translate(-2%, 1%);
    }
}
@media only screen and (max-width: 900px) {
    .minutes-page-container{
        flex-direction: column;
    }
    .minute-links{
        width: calc(100vw - 4em);
        height: fit-content;
    }
}
@media only screen and (max-width: 500px) {
    .minutes-page-container{
        flex-direction: column;
    }
    .minute-links{
        width: calc(100vw - 4em);
        height: fit-content;
    }
    .minute-links > a{
        font-size: 0.8em;
    }
    .minutes-header{
        font-size: 2.5em;
    }
    .minutes-description{
        font-size: 0.8em;
    }
}