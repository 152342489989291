.preloader{
    display: flex;
    justify-content: center;
}
.preloader-pacman{
    margin-left: 40px;
    width: fit-content;
}
.preloader-pacman .dot {
    display: inline-block;
    background: #666;
    height: 5px;
    width: 5px;
    margin-right: 20px;
    opacity: 0.7;
    border-radius: 50%;
    animation: fade-out 14s linear infinite;
    z-index: 4;
}
  
.preloader-pacman .pacman {
    display: inline-block;
    background: #326491;
    height: 20px;
    vertical-align: bottom;
    width: 20px;
    margin-right: 20px;
    opacity: 1;
    border-radius: 50%;
    position: relative;
    animation: move-forward 14s linear infinite;
    transform: translateX(-40px);
}

.pacman:hover{
    background-color: #E1C16E;
    cursor: pointer;
}
  
.preloader-pacman .dot:nth-child(1) {
    animation-delay: .5s;
}
  
.preloader-pacman .dot:nth-child(2) {
    animation-delay: 1s;
}
  
.preloader-pacman .dot:nth-child(3) {
    animation-delay: 1.5s;
}
  
.preloader-pacman .dot:nth-child(4) {
    animation-delay: 1.9s;
}
  
.preloader-pacman .dot:nth-child(5) {
    animation-delay: 2.3s;
}
  
.preloader-pacman .dot:nth-child(6) {
    animation-delay: 2.7s;
}
  
.preloader-pacman .dot:nth-child(7) {
    animation-delay: 3.1s;
}
  
.preloader-pacman .dot:nth-child(8) {
    animation-delay: 3.5s;
}
  
.preloader-pacman .dot:nth-child(9) {
    animation-delay: 3.9s;
}
  
.preloader-pacman .dot:nth-child(10) {
    animation-delay: 4.3s;
}
  
.preloader-pacman .dot:nth-child(11) {
    animation-delay: 4.7s;
}
  
.preloader-pacman .dot:nth-child(12) {
    animation-delay: 5s;
}
  
.preloader-pacman .dot:nth-child(13) {
    animation-delay: 5.4s;
}
  
.preloader-pacman .dot:nth-child(14) {
    animation-delay: 5.8s;
}
.preloader-pacman .dot:nth-child(15) {
    animation-delay: 6.2s;
}
.preloader-pacman .dot:nth-child(16) {
    animation-delay: 6.6s;
}

  
.preloader-pacman .pacman:before {
    content: '';
    display: inline-block;
    background: transparent;
    vertical-align: bottom;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    right: 0px;
    border-right: 10px solid #E5E4E2;
    opacity: 1;
    position: absolute;
    animation: eat .4s infinite;
}
.preloader-pacman .ghost {
    height: 25px;
    width:25px;
    background:#669;
    top:-22px;
    border-top-left-radius:50%;
    border-top-right-radius:50%;
    position:relative;
    transform: translateX(400px);
    animation:move-back-ghost 14s linear infinite;
    animation-delay: 8s;
    opacity:0;
}
.preloader-pacman .ghost:before {
    height:5px;
    content:'';
    width:3px;
    background:#fff;
    top:7px;
    left:7px;
    z-index:10;
    position:absolute;
    
}
.preloader-pacman .ghost:after {
    height:5px;
    content:'';
    width:3px;
    background:#fff;
    top:7px;
    right:7px;
    z-index:10;
    position:absolute;
}
@keyframes eat {
    50% {
      transform: translateX(10px) scale(0.1)
    }
}
@keyframes move-back {
    3% {opacity:1}
    35% {opacity:1}
    49% {opacity:0}
    50% {transform: translateX(0px);}
}

@keyframes move-back-ghost {
    3% {opacity:1}
    35% {opacity:0.5}
    45% {opacity:0}
    50% {transform: translateX(0px);}
}
@keyframes move-forward {
    50% {
      transform: translateX(400px);
    }
    50.1% {
      transform: translateX(400px) scaleX(-1);
      opacity: 1;
    }
    100% {
      transform: translateX(-40px) scaleX(-1);
    }
}
  
@keyframes fade-out {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
    }
    89.99% {
      opacity:0;
    }
    90% {
      opacity: 0.5;
    }
}

/*For smaller devices*/
@media (max-width: 999px) {
    .preloader-pacman{
        margin-left: 0px;
    }
    .preloader-pacman .dot {
        margin-right: 12px; /* Reduce margin between dots */
        animation: fade-out 10s linear infinite;
    }

    .preloader-pacman .pacman {
        transform: translateX(-20px); /* Reduce initial translation */
        animation: move-forward 10s linear infinite;
    }

    /* Reduce number of dots */
    .preloader-pacman .dot:nth-child(n+12) {
        display: none;
    }

    /* Adjust animation delay */
    .preloader-pacman .dot:nth-child(1) {
        animation-delay: .5s;
    }
  
    .preloader-pacman .dot:nth-child(2) {
        animation-delay: 1s;
    }
  
    .preloader-pacman .dot:nth-child(3) {
        animation-delay: 1.4s;
    }
  
    .preloader-pacman .dot:nth-child(4) {
        animation-delay: 1.8s;
    }
  
    .preloader-pacman .dot:nth-child(5) {
        animation-delay: 2.2s;
    }
  
    .preloader-pacman .dot:nth-child(6) {
        animation-delay: 2.6s;
    }
  
    .preloader-pacman .dot:nth-child(7) {
        animation-delay: 3s;
    }
  
    .preloader-pacman .dot:nth-child(8) {
        animation-delay: 3.4s;
    }
  
    .preloader-pacman .dot:nth-child(9) {
        animation-delay: 3.8s;
    }
  
    .preloader-pacman .dot:nth-child(10) {
        animation-delay: 4.2s;
    }
    .preloader-pacman .dot:nth-child(10) {
        animation-delay: 4.4s;
    }

    .preloader-pacman .pacman {
        transform: translateX(-5px); /* Reduce initial translation */
    }

    /* Reduce number of dots */
    .preloader-pacman .dot:nth-child(n+11) {
        display: none;
    }
    .preloader-pacman .ghost {
        height: 25px;
        width:25px;
        background:#669;
        top:-22px;
        border-top-left-radius:50%;
        border-top-right-radius:50%;
        position:relative;
        transform: translateX(200px);
        animation:move-back-ghost 10s linear infinite;
        animation-delay: 6.5s;
        opacity:0;
    }
    .preloader-pacman .ghost:before {
        height:5px;
        content:'';
        width:3px;
        background:#fff;
        top:7px;
        left:7px;
        z-index:10;
        position:absolute;
        
    }
    .preloader-pacman .ghost:after {
        height:5px;
        content:'';
        width:3px;
        background:#fff;
        top:7px;
        right:7px;
        z-index:10;
        position:absolute;
    }

    /* Adjust animation speed and distance */
    @keyframes move-forward {
        50% {
            transform: translateX(200px); /* Reduce distance */
        }
        50.1% {
            transform: translateX(200px) scaleX(-1);
            opacity: 1;
        }
        100% {
            transform: translateX(-5px) scaleX(-1); /* Reduce distance */
        }
    }

    @keyframes move-back-ghost {
        3% {opacity:1}
        35% {opacity:0.5}
        40% {opacity:0}
        50% {transform: translateX(0px);}
    }

    /* Increase speed */
    @keyframes fade-out {
        0% {
            opacity: 0;
        }
        40% {
            opacity: 0;
        }
        80% {
            opacity: 0;
        }
        90% {
            opacity: 0.5;
        }
    }
}