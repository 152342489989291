.binary-numbers{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -10;
    padding: 2em;
    box-sizing: border-box;
    max-height: 92vh;
}
.binary-row{
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}
.binary-digits{
    font-size: 2em;
    font-family: 'Tiny5';
    color: #D9D9D9;
}
@media only screen and (max-width: 500px){
    .binary-numbers{
        padding: 0;
    }
    .binary-digits{
        font-size: 1.2em;
    }
}