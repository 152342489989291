.event-day-container{
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
    margin: 0;
}
.event-day-info{
    width: 100%;
    height: fit-content;
    box-sizing: border-box;
    padding: 1em;
    box-shadow: 3px 3px #14283A;
    border: 1px solid #14283A;
    margin-top: 1em;
    background-color: #326491;
}
.event-day-title{
    font-size: 1em;
    margin: 0;
    color: white;
    border-bottom: 1px solid white;
}
.event-day-desc{
    font-size: 0.8em;
    color: white;
}
.event-day-time{
    color: white;
    font-size: 0.8em;
}