body {
  margin: 0;
  padding: 0;
  font-family: 'Intervogue Soft', 'Tiny5', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #E5E4E2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body > div {
  box-sizing: border-box;
  scrollbar-width: 2px;
  overflow: hidden;
}

@font-face {
  font-family: 'Intervogue Soft';
  src: url(../public/fonts/INTERVOGUE_SOFT_BOLD.otf);
  font-display: swap;
}

.tiny5-regular {
  font-family: "Tiny5", system-ui;
  font-weight: 400;
  font-style: normal;
}

.montserrat-regular {
  font-family: "Montserrat", system-ui;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}